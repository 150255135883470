import { Card, Heading, CardHeader, CardBody, Text } from "@chakra-ui/react";

const CommonCard = ({
  heading,
  subtitle,
  children,
}: {
  heading: string;
  subtitle: string;
  children: React.ReactNode;
}) => {
  return (
    <Card borderWidth='1px' borderColor='gray.200'>
      <CardHeader pb="0">
        <Heading as="h2" size="md">
          {heading}
        </Heading>
        <Text>{subtitle}</Text>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Card>
  );
};
export default CommonCard;
